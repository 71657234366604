// Custom variable file only for customer to use
.help-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h1 {
        color: #2F6AB1;
        font-size: 10rem;
    }
}