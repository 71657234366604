.form-control {

  @include box-shadow($input-box-shadow);

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }
}

// Make sure textareas don't shrink too much when resized
// https://github.com/twbs/bootstrap/pull/29124
// stylelint-disable selector-no-qualifying-type
textarea {
  &.form-control {
    max-height: 270px;
    @include max-screen(767) {
      max-height: 170px;
    }
  }
}

#goals {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#goals input[type="checkbox"] {
  display: none;
}

#goals span {
  display: inline-block;
  padding: 10px;
  text-transform: uppercase;
  border: 2px solid $primary;
  border-radius: 1rem;
  color: $primary;
  cursor: pointer;
}

#goals input[type="checkbox"]:checked + span {
  background-color: $primary;
  color: #fff;
}